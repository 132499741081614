.card_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.card_img img,
.card_img div {
  width: 120px !important;
  height: 80px !important;
  border-radius: unset !important;
  border: 0;
}

.card_content h1,
.card_content h2,
.card_content h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #10222cb8;
}

.card_content a {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02px;
  text-align: left;
  color: #5f3a2b;
}

.card_content a span {
  margin-right: 13px;
}
