@import '../../../../styles/customMediaQueries.css';

.block_background {
  width: 20%;
  height: 100%;
  position: absolute;
  background: linear-gradient(#dadada, #ffd387);
  display: block;
  z-index: -1;
  left: 8%;
}

.inner_block {
  margin: 25px;
  margin-left: 15px;
  position: relative;
  z-index: 1;
}

.booking_btn {
  display: flex;
  justify-content: end;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.card_text {
  width: 100%;
  margin-top: 20px;
  padding-left: 10px;
}
.card_text:first-child {
  margin-top: 0;
}

.card_text p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.33;
  font-size: 24px;
  font-weight: bold;
  color: var(--colorGrey900);
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.card_text h3 {
  font-weight: 500;
  font-size: 16px;
  margin-top: 3px;
}

.blogspage-style .inner_block {
  margin: 0;
}

.single_blogpage_img img,
.blogpage-image img {
  width: 100% !important;
}

.view_detail_btn a {
  color: var(--marketplaceColor);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.view_detail_btn a span {
  margin-right: 13px;
}

.blog_content {
  margin-top: 10px;
}

.blog_content p {
  margin: 0;
  padding: 0;
}

.inner_block_blogpage {
  width: 100%;
  margin: 0;
}

.main_heading h1,
.main_heading h2,
.main_heading h3 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #0a090b;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 43.2px;
  }
}

.blog_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.back_btn {
  padding: 2px 4px;
  background-color: var(--marketplaceColor);
  border-radius: 8px;

  @media (--viewportMedium) {
    padding: 4px 8px;
  }
}

.back_btn span {
  color: #fff;
}

.back_btn svg {
  margin-right: 10px;
  transform: rotateY(180deg);
}
