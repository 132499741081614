@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.singleblogpage-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 40px;
  max-width: 90%;
  margin-inline: auto;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.singleBlogPage {
  max-width: 100%;
  margin: 0;
  padding: 0 !important;
}

.table-of-content {
  width: 100%;
  @media (--viewportMedium) {
    position: sticky;
    top: 74px;
    min-width: 13.5%;
    max-width: 13.5%;
  }
}

.table-of-content .heading,
.recent-blogs-sec .heading {
  margin-top: 0;
  color: #0a090b;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  letter-spacing: -0.03em;
  text-align: left;
}

.recent-blogs-sec {
  width: 100%;
  @media (--viewportMedium) {
    min-width: 23.8%;
    max-width: 23.8%;
  }
}

.content_sec_links {
  display: flex;
  flex-direction: column;
}

.table-of-content .heading {
  text-align: start;

  @media (--viewportMedium) {
    text-align: center;
  }
}

.section_link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  margin-bottom: 1px;
  padding-inline: 20px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05000000074505806px;
  text-decoration: none !important;
  color: #4f4d55;
  border-radius: 8px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  @media (--viewportMedium) {
    justify-content: center;
    padding-inline: 5px;
  }
}

.section_link.active,
.section_link:hover {
  background-color: #f9e4c5;
  color: #0a090b;
}
