@import '../../styles/customMediaQueries.css';

.icons_div {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (--viewportMedium) {
    gap: 20px;
  }
}

.icon_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-transform: capitalize;
  color: #000000;
}

.copy_icon {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: unset;
  cursor: pointer;
}

.copied_text {
  position: absolute;
  right: 0;
  left: auto;
  bottom: -40px;
  background: antiquewhite;
  padding: 2px 5px;
}
