@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.blogspage-custom-style {
  max-width: 86.53%;
  padding-inline: 0 !important;
}

.custom_pagination {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 86.53%;
  margin: 0 auto;
}

.custom_pagination button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

.custom_pagination ul {
  display: flex;
  align-items: center;
  gap: 30px;
}

.custom_pagination ul li {
  cursor: pointer;
}

.active_page {
  padding: 21px 5px 18px;
  border-bottom: 3px solid var(--colorBlack);
  color: var(--colorBlack);
}

.bg-blogsection {
  background-color: var(--marketplacecolor) !important;
}

.singleblogpage-column-sec-bg {
  background: #f9e4c5 !important;
}
